import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to Use Javascript Generators",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:54:56.000Z",
  "url": "/2018/02/how-to-use-javascript-generators/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I have tried to learn generators for quite a while, but for each time that I started, I said to myself:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“`}{`that`}{`’`}{`s enough, I`}{`’`}{`ll learn when I`}{`’`}{`ll need it`}{`”`}</p>
    </blockquote>
    <p>{`Well not this time! after you really understand the concept of generators you will find the true magic of it, the vast capabilities you could do with that.`}</p>
    <p>{`Generators are reading and sending data, and can `}{`“`}{`run`}{`”`}{` endlessly.`}</p>
    <p>{`Let`}{`’`}{`s learn by examples:`}</p>
    <pre><code parentName="pre" {...{}}>{`function* simpleGenerator() { //the "*" mans its a generator  
    yield 1;
    yield 2;
}
let ge = simpleGenerator();  
console.log(ge.next()); // { value: 1, done: false }  
console.log(ge.next()); // { value: 2, done: false }  
console.log(ge.next()); // { value: undefined, done: true }  
`}</code></pre>
    <p>{`each yield returns a value and `}{`“`}{`stops`}{`”`}{` the function at its place.`}</p>
    <p>{`When you call `}{`“`}{`next`}{`”`}{` again, your generator will continue from its last stopped point.`}</p>
    <p>{`When you `}{`“`}{`yields`}{`”`}{` are finished the generator will be marked as `}{`“`}{`done`}{`”`}{` and you can do a simple `}{`“`}{`return`}{`”`}{` if you would like.`}</p>
    <p>{`Now lets do a quick example with sending data to the `}{`“`}{`yields`}{`”`}{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`function* simpleGenerator() {  
    let x = yield 5;
    let y = 2 + (yield x);
    return y;
}

let ge = simpleGenerator();  
console.log(ge.next("dontcare")); //{ value: 5, done: false }  
console.log(ge.next(100)); //{ value: 100, done: false }  
console.log(ge.next(1000)); //{ value: 1002, done: true }  
`}</code></pre>
    <p>{`as you can see here we pass items to the yield.`}</p>
    <p>{`The first yield item we pass is ignored, as always the item we pass will go the the previous yield that was used.`}</p>
    <p>{`In the last next, we see the generator was finished and got `}{`“`}{`done: true`}{`”`}{`. This is because yield were finished and we got returned the last value.`}</p>
    <p>{`Now a simple fibonacci sequence in a generator:`}</p>
    <pre><code parentName="pre" {...{}}>{`function* fibonacciRunner() {  
      let first = 1;
      let second = 1;
    yield first;
     yield second;

      while(true) {
          let result = first + second;
          first = second;
          second = result;
          yield result;
      }
}

var fibonacci = fibonacciRunner();

console.log(fibonacci.next().value); // 1  
console.log(fibonacci.next().value); // 1  
console.log(fibonacci.next().value); // 2  
console.log(fibonacci.next().value); // 3  
console.log(fibonacci.next().value); // 5  
console.log(fibonacci.next().value); // 8  
console.log(fibonacci.next().value); // 13  
console.log(fibonacci.next().value); // 21  
console.log(fibonacci.next().value); // 34  
`}</code></pre>
    <p>{`This generator will run endlessly, as it will never stop yielding the next response.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      